<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <base-login-material-wizard
        ref="baseLoginMat"
        v-model="tab"
        :available-steps="[1]"
        :items="tabs"
        :disabledBtnLogar="disabledBtnLogar"
        :loading="loading"
        class="mx-auto"
        @click:next="next(valid)"
        :btnLogarLabel="coolDown ? `${coolDown} segundos` : 'Enviar'"
      >
        <v-tab-item class="pb-1"></v-tab-item>
          <v-tab-item class="pb-1">
            <form>
              <v-row
              class="mx-auto"
              justify="space-around"
              >
              
              <small>Informe seu e-mail.</small>
              <small>Enviaremos um link para redefinir sua senha.</small>

              <v-col
                cols="12"
                md="11"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    color="primary"
                    label="E-mail*"
                    prepend-icon="mdi-email-outline"
                    @keyup.enter="next(valid)"
                    validate-on-blur
                  />
                </validation-provider>

              </v-col>

            </v-row>
          </form>
        </v-tab-item>
      </base-login-material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import authService from '@/services/AuthService';

export default {
  name: 'LoginWizard',

  components: {
    BaseLoginMaterialWizard: () =>
      import('@/components/login/base/BaseLoginMaterialWizard.vue'),
  },

  data() {
    return {
      tab: 1,
      tabs: ['Esqueci minha senha'],
      email: '',
      loading: false,
      coolDown: 0,
    };
  },

  computed: {
    disabledBtnLogar() {
      return !!this.coolDown || !this.email || !(/^\S+@\S+\.\S+$/.test(this.email));
    }
  },

  methods: {
    next() {
      if (this.disabledBtnLogar) return;
      this.loading = true;
      authService.forgotPassword(this.email)
        .then(() => this.handleSuccess())
        .catch((error) => {
        let message = error?.data?.message || 'Falha ao enviar e-mail';

        if (error.status === 422) {
          const data = error.data;
          message = error.data.message;

          if (data.errors.email?.length) message = data.errors.email[0];
        }
        
          this.$toast.error(message, '', {
            position: 'topRight',
            timeout: 5000
          });
        }).finally(() => {
          this.loading = false;
        });
    },

    handleSuccess() {
      this.$toast.info('E-mail enviado com sucesso. Verifique seu e-mail', '', {
        position: 'topRight',
        timeout: 10000
      });
      this.coolDown = 60;
      this.handleCoolDown();
    },

    handleCoolDown() {
      setTimeout(() => {
        if (!this.coolDown) return false;
        this.coolDown -= 1;
        this.handleCoolDown();
      }, 1000);
    }
  }
}
</script>